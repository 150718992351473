/**
 * Fabrik Framework
 * @root default
*/

@import 'partials/_variables.scss';

[class^="icon-"], [class*=" icon-"] {
  height: 24px;
  width: 24px;
  display: inline-block;
  fill: currentColor;
  vertical-align: -5px;
  margin-right: .5rem;

  @media (--l) {
    height: 16px;
    width: 16px;
    vertical-align: -2px;
  }
}

#site {
  margin-bottom: 3rem;
}

hr {
  height: 1px;
  width: 100%;
  border: none;
  border-top: 1px solid $lightGrey;
  margin-bottom: 2rem;
}

.button {
  color: white;
  background: $orange;
  padding: 1rem 2rem;
  margin: 0 auto;
  display: inline-block;
  border: none;

  &--wide {
    width: 100%;
    text-align: center;
    font-size: 1.9rem;
    padding: 2rem;
  }
}

.button--border {
  border: none;
  background: none;
  color: $green;
  padding: 0;
  border: 2px solid $green;
  padding: .5rem;
  text-transform: uppercase;
  font-size: 1.4rem;
}

.default__header {
  border-bottom: 1px solid $lightGrey;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
}

.Breadcrumb {
  margin: 3rem 0;
  padding: 0;
  list-style: none;
  font-size: 1.4rem;

  ul {
    display: inline-block;
    margin: 0;
    padding: 0;
  }

  li {
    display: inline-block;
  }
}

/* Single Prod page */
.product {

  fieldset {
    border: none;
    padding: 0;
  }

  .FormItem {
    padding-left: 0;
  }

  hr {
    border: none;
    border-top: 1px solid $lightGrey;
    height: 1px;
    margin: 1rem 0;

  }

  label, .label {
    width: auto;
    margin-left: auto;
    margin-bottom: 0;
    margin-right: 0;
    padding: 0;
  }

  .FormItem {
    margin-bottom: 2rem;

    .Checks {
      padding-left: 2rem;

      label {
        display: block;
      }
    }

    .FormHelp {
      display: inline-block;
    }
  }
  .BuyFormQuantity {
    input {
      border: 2px solid $lightGrey;
      text-align: center;
      padding: 1rem;
    }
  }

  .FormSubmit {
    display: block;
    clear: both;
    margin-top: 3rem;

    .SubmitButton {
      @extend .button;

      border: none;
      border-radius: 0;
      text-transform: uppercase;
    }
  }

  #ProductImageCarousel,
  #ProductThumbnailCarousel {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;

    li {
      display: inline-block;
    }
  }

  #ProductImageCarousel {
    margin-bottom: 3rem;
  }

  #ProductThumbnailCarousel {

    margin-bottom: 3rem;

    @media (--l) {
      margin-bottom: 0;
    }

    li {
      cursor: pointer;
    }
  }

  .slick-arrow,
  .slick-dots button {
    border: none;
    background: none;
    font-size: 0;

    &:focus {
      outline: none;
    }
  }

  .slick-arrow {
    position: absolute;
    top: 50%;
    z-index: 10;
    padding: 1rem;
    background: rgba(255, 255, 255, .7);

    svg {
      height: 34px;
      width: 34px;
    }

    &.slick-prev {
      left: 0;
    }

    &.slick-next {
      right: 0;
    }

  }

  .slick-dots {

    padding: 0;
    margin: 0;
    text-align: center;

    li {
      border-radius: 100%;
      border: 1px solid #333;
      height: 10px;
      width: 10px;
      display: inline-block;
      padding: 0;
      margin: 0 3px;
      padding: 1px 0 0 1px;

      button {
        display: block;
        width: 6px;
        height: 6px;
        border-radius: 100%;
        margin: 0;
        padding: 0;

        &:active,
        &:focus {
          outline: none;
        }
      }

      &.slick-active {
        button {
          background: #333;
        }
      }
    }
  }

  table{
    font-size: 1.1rem;
    margin: 2em 0;

    td{
      padding: .5em;
      border-left: 1px solid #000;

      &:first-child{
        border: none;
      }


    }

    tr{
      &:first-child{
        background-color: #e9eaec;
        font-weight: bold;
        border-top: 2px solid orange;
        border-bottom: 2px solid orange;
        text-align: center;
      }

      &:nth-child(odd){
        background-color: #eaeaea;
      }
    }
  }

  #ProductThumbnailCarousel{
    .slick-track{
      li{
        border: 1px solid #eaeaea;
        height: 7.2rem;
        position: relative;
        margin: 0 .25em;

        img{
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }

  .slick-slide {

    img{
      width: 100%;
    }

    &:focus {
      outline: none;
    }

    img:focus,
    img:active {
      outline: none;
    }
  }
}

.FormItem {
  margin-bottom: 2rem;
}

.Cart, .CheckoutConfirmation, .CheckoutThanks {

  text-align: left;

  #CartTable {
    width: 100%;
    margin-bottom: 2rem;

    tr {
      border-bottom: 1px solid $lightGrey;

      td {
        padding: 3rem 1rem;

        &.CartPic {
          padding: 3rem 0;
        }
      }
    }

    .CartQuantity {
      text-align: center;

      input {

        width: 50%;
        text-align: center;

        @media (--l) {
          width: 20%;
        }

      }

      a {

      }
    }

    .CartProduct {

      h3 {
        font-size: 1.4rem;

        @media (--l) {
          font-size: 2rem;
        }
      }

      dl {

        font-size: 1.4rem;

        dd, dt {
          padding: 0;
          margin: 0;
        }

        dd {
          font-weight: 400;
          margin-bottom: 1rem;
        }
      }
    }
  }

  #UpdateCartButton {
    border: none;
    background: none;
    color: $green;
    padding: 0;
    border: 2px solid $green;
    padding: .5rem;
    float: right;
    text-transform: uppercase;
    font-size: 1.4rem;
  }

  #SubmitCampaignCode,
  #CartShareButtons {
    a {
      font-size: 1.4rem;
      display: block;
      margin-bottom: .7rem;
    }
  }

  #CartShareButtons {
    margin-bottom: 2rem;
  }

  #SubmitCampaignCode {
    input {
      display: inline-block;
      width: 60%;
    }
    button {
      @extend .button--border;
      padding: .5rem;
      display: inline-block;
      width: 30%;
    }

    @media (--l) {
      input,
      button {
        width: auto;
        display: inline;
      }

      label {
        display: block;
      }
    }
  }

}

/* Thanks page */
.CheckoutThanks {

  .FormHelp {
    margin-top: 2rem;
  }

  h3 {
    margin-top: 3rem;
  }

  table {

    h3 {
      margin-top: 0;
    }

  }
}

.cart__totalsum {

  .row {
    margin-bottom: 2rem;
  }


  .button {
    width: 100%;
    text-align: center;
  }

  .price {
    @media (--l) {
      text-align: right;
    }
  }

  h3 {
    font-weight: 700;
    font-size: 1.6rem;
    border-bottom: 1px solid $lightGrey;
    line-height: 1.4;
    margin-bottom: 2rem;
  }
}

.add-border-right {

  margin-bottom: 2rem;

  @media (--l) {
    border-right: 1px solid $lightGrey;
    padding-right: 2rem;
    margin-bottom: 0;

    & + div {
      border-left: 1px solid $lightGrey;
      margin-left: -1px;
    }
  }

  .FormSubmit {
    border-bottom: 1px solid $lightGrey;
    padding-bottom: 3rem;

    @media (--l) {
      border: none;
    }
  }
}

.checkout__cart-preview {

  border-bottom: 1px solid $lightGrey;
  font-size: 1.4rem;
  margin-bottom: 2rem;

  .cart-preview__item {
    margin-bottom: 2rem;

    h3 {
      font-size: 1.6rem;
    }
  }

  .cart-preview__image {
    text-align: center;
  }
}

#Checkout,
.AccountPage {

  .label, [for="OrderComments"] {
    text-transform: uppercase;
  }

  h2 {
    text-transform: uppercase;
    margin-bottom: 3rem;
  }

  dl {
    dd, dt {
      margin: 0;
      padding: 0;
    }

    dt {
      font-weight: 700;
      text-transform: uppercase;
      margin-top: 2em;
    }

    dd {
      margin-bottom: 0rem;

      a {
        display: inline-block;
        font-size: 1.4rem;
        border: 1px solid $green;
        padding: .5rem;
        margin-top: 1rem;
        text-transform: uppercase;
        text-align: center;
      }
    }
  }

  header {
    margin-bottom: 4rem;
  }

  fieldset {
    border: none;
    padding: 0;
  }

  .checkout__item {
    padding: 4rem;

    &--border {
      border-left: 1px solid $lightGrey;

    }

    #AccountLogin {

      label {
        display: block;
      }

      input {
        margin-bottom: 1rem;
      }

      button {
        @extend .button;
      }

    }
  }

  #CheckoutStage {

    @media (--l) {
      display: table;
      padding: 0 0 2rem 0;
      margin: 0 0 2rem 0;
      width: 100%;
    }

    li {
      @media (--l) {
        display: table-cell;
        text-align: center;
        padding: 1rem;
        font-size: 1.1rem;
      }

      @media (--l) {
        font-size: 1.6rem;
      }
    }

    a {
      color: $baseFontColor;
    }

    .CurrentCheckoutStage {
      color: $green;

      @media (--l) {
        border-bottom: 2px solid $green;
      }
    }

    .CurrentCheckoutStage {
      font-weight: 700;
    }

    .CompletedCheckoutStage {

      border: none;

      a {
        color: $green;
      }
    }
  }

  .checkout__new-customer,
  #EditContactInfoForm {
    label,
    input[type="text"],
    input[type="email"],
    input[type="password"],
    .InlineCheck {
      display: block;
      width: 100%;

      @media (--l) {
        width: 70%;
      }
    }

    #EditContactInfoForm {
      label,
      input[type="text"],
      input[type="email"],
      input[type="password"],
      .InlineCheck {
        @media (--l) {
          width: 40%;
        }
      }
    }

    input[type="text"],
    input[type="email"],
    input[type="password"],
    .InlineCheck,
    select {
      margin-bottom: 2rem;
    }

    input[type="text"],
    input[type="password"],
    input[type="email"] {
      padding: .5rem;
    }

    .GroupLabel,
    .sel_inner {
      display: none;
    }

    #CheckoutShippingAddressToggle {
      margin-bottom: 2rem;
    }
  }

  .FormErrorMessage {
    color: red;
  }
}

#PreviewProducts {
  .SubmitButton {
    @extend .button;
    width: 100%;
    font-size: 3rem;
  }

  textarea {
    width: 100%;
  }
}

/* Product list */

.product__header {
  margin-bottom: 2rem;
  border-bottom: 1px solid $lightGrey;
  overflow: hidden;

  h1 {
    float: left;
  }

}

#PaginationSortForm {
  float: right;

  .sel_inner {
    display: none;
  }

  label {
    font-size: 1.4rem;
  }

  label,
  .CustomSelectWrap,
  .GroupItem div {
    display: inline-block;
  }
}

.PageNavigation {
  border-top: 1px solid $lightGrey;
  padding-top: 2rem;
  text-align: center;
  font-weight: 400;

  li {
    display: inline-block;
    margin: 0 1rem;
  }

  .CurrentPage {
    font-weight: 700;
  }
}

/* Account */

.AccountPage
#InfoPage,
 {

  .FormHelp {
    font-size: 1.2rem;
    font-style: italic;
    margin-bottom: 2rem;

    @media (--l) {
      width: 70%;
    }
  }

  fieldset {
    border: none;
    margin: 0;
    padding: 0;

    h2 {
      display: none;
    }

    .SubmitButton {
      @extend .button;
    }
  }

  .FormItem {
    margin: 0;

    label,
    input {
      display: inline-block;
    }

    input[type="checkbox"] {
      display: inline-block;
    }

    input {
      margin-bottom: 1rem;
    }
  }

  .FormAltAction {
    display: inline-block;
  }

}

/* News */

.NewsOverview {
  font-style: italic;
}
